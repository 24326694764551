body {
  margin: 0;
}

.scene {
  position: fixed;
  top: 0;
  left: 0;
}

.info {
  position: fixed;
  margin: 0;
  padding: 5px 10px;
  bottom: 20px;
  left: 20px;
  color: white;
  background-color: #0000007a;
  font-family: monospace;
  line-height: 1.5;

  &.hidden {
    display: none;
  }

  a {
    color: inherit;
  }
}
